const content = {
  en: {
    position: 'Position',
    office: 'Office',
    team: 'Team'
  },
  de: {
    position: 'Position',
    office: 'Office',
    team: 'Team'
  }
};

export default content;
