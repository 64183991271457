import { getLangFromLocale, getCountryFromLocale } from '../Helpers/Utils';

import en_DE from './en_DE';
import de_DE from './de_DE';
import en_US from './en_US';

const locales = [
  {
    name: 'en_DE',
    translations: en_DE
  },
  {
    name: 'de_DE',
    translations: de_DE
  },
  {
    name: 'en_US',
    translations: en_US
  }
];

export const checkIfLangIsAvailable = (lang, activeLocale) => {
  const langToCheck = lang.toLowerCase();
  const activeLocaleCountry = getCountryFromLocale(activeLocale);

  return getAvailableLangsInCountry(activeLocaleCountry).includes(langToCheck);
};

export const getAvailableLangsInCountry = country => {
  return locales
    .filter(item => getCountryFromLocale(item.name) === country.toUpperCase())
    .map(item => getLangFromLocale(item.name));
};

export const i18n = localeName => {
  return locales.find(item => item.name === localeName)['translations'];
};
