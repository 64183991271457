import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './Development.css';

const development = props => {
  const assets = '/Assets/';
  const {
    content: { items, title, subTitle, text },
    deviceWidth
  } = props;

  const devDesktop = items.map((el, i) => {
    return (
      <React.Fragment key={i}>
        <div className="items__item">
          {el.title && <h2 className="items__title">{el.title}</h2>}
          <div className="items__body">
            <img className="items__img" alt="" title="" src={assets + el.img} />
            <div className="items__text">
              <h4 className="items__inner-title">{el.innerTitle}</h4>
              <p className="items__inner-subtitle">
                {el.innerSubtitle}
                <img alt="" src={`${assets}info-icon.svg`} className="items__info-icon" />
              </p>
              <p className="items__inner-copy">{el.innerCopy}</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });
  const devMobile = (
    <Carousel
      className="items__carousel"
      autoPlay
      swipeable={true}
      showThumbs={false}
      infiniteLoop={true}
      emulateTouch={true}
      interval={6000}
      transitionTime={600}
      showStatus={false}
    >
      {items.map((el, i) => {
        return (
          <div key={i} className="items__item">
            <div className="items__inner">
              <h2 className="items__title">{el.title}</h2>
              <div className="items__body">
                <img className="items__img" alt="" title="" src={assets + el.img} />
                <div className="items__text">
                  <h4 className="items__inner-title">{el.innerTitle}</h4>
                  <p className="items__inner-subtitle">{el.innerSubtitle}</p>
                </div>
              </div>
            </div>
            <p className="items__inner-copy items__inner-copy--mobile">{el.innerCopy}</p>
          </div>
        );
      })}
    </Carousel>
  );

  return (
    <section id="development" className="section section--development">
      <div className="section__wrapper section__wrapper--development">
        <div className="section__head section__head--development">
          <h2 className="section__title blue-underline">{title}</h2>
        </div>
        <div className="section__content">
          <p className="section__text section__text--development">{text}</p>
          {subTitle && <h3 className="section__sub-title">{subTitle}</h3>}
          <div className="items">{deviceWidth >= 992 ? devDesktop : devMobile}</div>
        </div>
      </div>
    </section>
  );
};

export default development;
