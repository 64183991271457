import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
// import YouTube from 'react-youtube';
import './Video.css';

let ready = false;
let youtubeElement = null;

const Video = props => {
  const [playing, setPlaying] = useState(false);
  const {
    deviceWidth,
    content: { coverImg, playImg }
  } = props;

  const assets = process.env.PUBLIC_URL + '/Assets/';
  const videoId = '389282513';

  const play = () => {
    if (ready) {
      setPlaying(true);
      youtubeElement.play();
    } else {
      setTimeout(play, 100);
    }
  };

  const onReadyYT = event => {
    ready = true;
    youtubeElement = event;
  };

  return (
    <section className={`section section--video ${deviceWidth >= 992 ? 'section--grey' : ''}`}>
      <div className="section__wrapper">
        <div className="video__cont">
          <div className={!playing ? 'video__overlay' : 'hidden'}>
            <img
              alt="play btn"
              onClick={play}
              className={'video__play'}
              src={`${assets}${playImg}`}
            />
            <img alt="video cover" className="video__cover" src={`${assets}${coverImg}`} />
          </div>
          <div className="video__video">
            <Vimeo
              video={videoId}
              showByline={false}
              showPortrait={false}
              showTitle={false}
              className="video__frame-parent"
              onReady={event => onReadyYT(event)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Video;
