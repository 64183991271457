const data = {
  head: {
    title: 'Hear.com Careers',
    description: 'Hear.com Careers',
    favicon: 'favicon-us.ico'
  },
  benefits: {
    title: 'Perks and Benefits',
    items: [
      {
        title: 'Free snacks and drinks',
        icon: 'us/Benefits/fruits.svg',
        text:
          'To help give you an extra boost of energy, we offer a wide variety of snacks, energy drinks, coffee and more!'
      },
      {
        title: 'Come as you are',
        icon: 'us/Benefits/shoe.svg',
        text:
          'Whether you’re a suit and tie kind of guy or jeans and t-shirt kind of a girl, we welcome you as you are. We just want you to feel comfortable and happy.'
      },
      {
        title: 'Startup feeling',
        icon: 'us/Benefits/rocket.svg',
        text:
          'We frequently host team bonding events where we bring everyone together to celebrate all our accomplishments and have a great time.'
      },
      {
        title: 'Great benefits package',
        icon: 'us/Benefits/fitness.svg',
        text:
          'We got all your personal needs covered with our amazing medical, dental, vision and 401k plans. '
      }
    ],
    trustImg: {
      desktop: 'us/Trust/kununu_desktop.jpg',
      mobile: 'us/Trust/kununu_mobile.png'
    }
  },
  carousel: {
    slides: [
      {
        img: {
          mobile: 'us/Carousel/miracle_m.jpg',
          desktop: 'us/Carousel/miracle.jpg'
        },
        title: 'From tiny start-up to the fastest-growing hearing care company in the world',
        text:
          'We’ve gone from a handful of employees in a tiny Berlin office to over 1200 employees globally in just 7 years. We came to the US in 2015 and have quickly grown to over 300 employees with offices in Miami and Denver. With hearing technology moving at the speed of light, we’re continuing to expand and innovate to help as many people as possible see the life-changing benefits of better hearing.'
      }
    ]
  },
  development: {
    title: 'Grow with us',
    subTitle: 'Employee spotlight',
    text:
      "To grow the company, it's important to develop our people. With the rate of innovation at hear.com, we provide the environment, encouragement, and opportunities to develop both professionally and personally. And we give you the freedom to find your potential.",
    items: [
      {
        img: 'us/Development/portrait_01.jpg',
        innerTitle: 'Jennifer',
        innerSubtitle: 'Team Lead, Denver',
        innerCopy:
          '“I love that we are the best. We have the best products in the industry, the best technology, the best marketing, and the best teammates. But best of all, I get to work for a company that is flexible and supportive, so I can be the best mom.”'
      },
      {
        img: 'us/Development/portrait_02.jpg',
        innerTitle: 'Coralia',
        innerSubtitle: 'Marketing Manager, Coral Gables',
        innerCopy:
          '“It’s awesome to work for the fastest growing hearing care company in the world! Their flat hierarchy encourages us to share our own ideas with the rest of the team and work independently on projects we believe will be successful.”'
      },
      {
        img: 'us/Development/portrait_03.jpg',
        innerTitle: 'Nathan',
        innerSubtitle: 'Visual Designer, Denver',
        innerCopy:
          '“I love that hear.com gives you the freedom to carve your own path. They allow you to have ownership over your work and push for you to bring your own ideas to the table. I’ve been able to grow more at hear.com than I have at any other job in my past.”'
      }
    ]
  },
  footer: {
    social: [
      {
        title: 'Facebook',
        icon: 'facebook.svg',
        url: 'https://www.facebook.com/hearcom'
      },
      {
        title: 'Instagram',
        icon: 'instagram.svg',
        url: 'https://www.instagram.com/hearcom_usa/'
      },
      {
        title: 'youtube',
        icon: 'youtube.svg',
        url: 'https://www.youtube.com/channel/UCrmfqt_VsrS8SJY089jTvSw'
      }
    ],
    international: {
      title: 'audibene/hear.com international:',
      links: [
        {
          title: 'Netherlands',
          icon: 'flag_netherlands@2x.png',
          url: 'https://www.audibene.nl'
        },
        {
          title: 'Switzerland',
          icon: 'flag_switzerland@2x.png',
          url: 'https://www.audibene.ch'
        },
        {
          title: 'Canada',
          icon: 'flag_canada@2x.png',
          url: 'https://www.hear.com/ca'
        },
        {
          title: 'France',
          icon: 'flag_france@2x.png',
          url: 'https://www.audibene.fr/'
        },
        {
          title: 'USA',
          icon: 'flag_usa@2x.png',
          url: 'https://www.hear.com/'
        },
        {
          title: 'India',
          icon: 'IN_flag@2x.png',
          url: 'https://www.hear.com/in'
        },
        {
          title: 'Malaysia',
          icon: 'flag_malaysia@2x.png',
          url: 'https://www.hear.com/my'
        },
        {
          title: 'Germany',
          icon: 'flag_germany@2x.png',
          url: 'https://www.audibene.de/'
        },
        {
          title: 'South Korea',
          icon: 'flag_south_korea@2x.png',
          url: 'https://www.hear.com/kr'
        }
      ]
    },
    nav: [
      {
        title: 'Imprint',
        url: 'https://www.hear.com/imprint/'
      },
      {
        title: 'Privacy Policy',
        url: 'https://www.hear.com/privacy-policy/'
      },
      {
        title: 'Do Not Sell My Info',
        url: 'https://www.hear.com/privacy-request/'
      },
      {
        title: 'Press',
        url: 'https://www.hear.com/press/'
      }
    ]
  },
  header: {
    logo: 'us/Logos/logo.svg',
    items: [
      {
        text: 'Who we are',
        anchor: 'who'
      },
      {
        text: 'How we grow',
        anchor: 'development'
      },
      {
        text: 'Benefits',
        anchor: 'benefits'
      },
      {
        text: 'Jobs',
        anchor: 'jobs'
      }
    ]
  },
  hero: {
    img: 'us/Hero/hero-audibene-de.jpg',
    title: 'Jobs at hear.com',
    subtitle: "We make people's lives better.",
    cta: 'Open positions',
    videoId: '389278337'
  },
  jobs: {
    title: 'Jobs'
  },
  offices: {
    title: {
      mobile: 'Our office locations - Where do you want to work?',
      desktop: 'Our office locations - Where do you want to work?'
    },
    items: [
      [
        {
          block: 'image',
          img: 'us/Offices/o_01.jpg'
        },
        {
          block: 'text',
          title: 'Coral Gables, Florida',
          text:
            "Our US journey started in beautiful Coral Gables and this office continues to thrive on the culture that kick-started our growth. You'll find Marketing, Sales and Operations come together in our casual space, in-house game room or at the ping pong table."
        },
        {
          block: 'image',
          img: 'us/Offices/o_02.jpg'
        }
      ],
      [
        {
          block: 'image',
          img: 'us/Offices/o_03.jpg'
        },
        {
          block: 'image',
          img: 'us/Offices/o_04.jpg'
        },
        {
          block: 'text',
          title: 'Denver, Colorado',
          text:
            'Our newest and most innovative office yet, Denver houses our state-of-the-art hearing aid studio and brings together a number of cross-functional teams. With open space, awesome colleagues and breathtaking views of the Rockies, this campus thrives.'
        }
      ],
      [
        {
          block: 'text',
          title: 'Your home office',
          titleMobile: 'Your home office',
          text:
            "If you don’t live near our two offices, don’t worry. With today's technology, you can collaborate with colleagues, connect with customers, and have a big impact at hear.com while working from the comforts of your home."
        },
        {
          block: 'image',
          img: 'us/Offices/o_05.jpg'
        },
        {
          block: 'image',
          img: 'us/Offices/o_06.jpg'
        }
      ]
    ]
  },
  values: {
    title: 'Our values',
    items: [
      {
        title: 'Transparent',
        icon: 'us/Values/cards.svg',
        text:
          'We are open and transparent with every team member about our goals, strategies, and revenue.'
      },
      {
        title: 'Data-driven',
        icon: 'us/Values/graph.svg',
        text:
          'Not only do we use our creativity to come up with new ideas, but we also rely on performance metrics and data to support them.'
      },
      {
        title: 'Try, try again',
        icon: 'us/Values/risk.svg',
        text:
          'We prefer to try something that fails than to not try anything at all. So we encourage our team to share new ideas and test them out.'
      },
      {
        title: 'Work hard, play hard',
        titleMobile: 'Work & play hard',
        icon: 'us/Values/work-party.svg',
        text:
          'There’s nothing better than celebrating all your hard work with great food, great drinks, and great company.'
      }
    ]
  },
  who: {
    title: 'We’re on a mission',
    text:
      "We believe that in today's world, everyone should hear well to live well. That's why we're on a mission to advance hearing care and deliver the magic of hearing. Join our team and help people thrive by keeping them happy, healthy, sharp, and connected.",
    success: {
      title: 'About us',
      items: [
        {
          number: '2012',
          text: 'Founded'
        },
        {
          number: '10',
          text: 'Countries'
        },
        {
          number: '1,200',
          text: 'Colleagues'
        },
        {
          number: '25',
          text: 'Nationalities'
        }
      ]
    }
  },
  work: {
    title: 'How we work',
    img: {
      desktop: 'us/Work/presentation.jpg',
      mobile: 'us/Work/presentation-mobile.jpg'
    },
    textBlock: {
      title: 'One team, one dream',
      text:
        'We work in cross-functional teams instead of splitting everyone up into departments. We don’t believe in hierarchy, which gives everyone an opportunity to share their ideas and opinions. So whether you’re a finance intern or a senior marketing specialist, your voice is heard at hear.com.'
    }
  }
};

export default data;
