import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';

function CarouselItem({ img, title, text }) {
  return (
    <div className="audicarousel__item">
      <img className="audicarousel__img" alt="" src={img} />
      <div className="audicarousel__content">
        <h5 className="audicarousel__subtitle">{title}</h5>
        <p className="audicarousel__text">{text}</p>
      </div>
    </div>
  );
}

function StoryCarousel(props) {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { slides },
    deviceWidth
  } = props;

  return (
    <section className="section section--carousel">
      <div className="section__wrapper">
        <div className="section__content audicarousel">
          {slides.length > 1 ? (
            <div className="audicarousel__slider">
              <Carousel
                autoPlay
                showThumbs={false}
                infiniteLoop={true}
                emulateTouch={true}
                interval={6000}
                transitionTime={600}
                showStatus={false}
                swipeScrollTolerance={1}
              >
                {slides.map((el, i) => {
                  return (
                    <CarouselItem
                      key={i}
                      img={assets + (deviceWidth >= 660 ? el.img.desktop : el.img.mobile)}
                      title={el.title}
                      text={el.text}
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <CarouselItem
              img={assets + (deviceWidth >= 660 ? slides[0].img.desktop : slides[0].img.mobile)}
              title={slides[0].title}
              text={slides[0].text}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default StoryCarousel;
