import React, { Component } from 'react';
import YoutubeBackgroundCustom from '../YoutubeBackgroundCustom/YoutubeBackgroundCustom';
import './Hero.css';

const assets = process.env.PUBLIC_URL + '/Assets/';

class Hero extends Component {
  state = { playState: '' };

  smoothScroll = target => {
    window.scrollTo({
      top: document.getElementById(target).offsetTop - (this.state.deviceWidth < 992 ? 90 : 120),
      behavior: 'smooth'
    });
  };

  handlePlay = () => {
    if (this.state.playState !== 'playing') {
      this.setState({ playState: 'playing' });
    }
  };

  render() {
    const {
      content: { img, title, subtitle, cta, videoId }
    } = this.props;
    const heroImageStyle = { backgroundImage: 'url(' + assets + img + ')' };
    let playerClasses = `${this.state.playState} section__wrapper section__wrapper--hero`;

    return (
      <section className="section section--hero" style={heroImageStyle}>
        <YoutubeBackgroundCustom
          videoId={videoId}
          overlay="rgba(12, 44, 83, 0.5)"
          className={playerClasses}
          onPlay={this.handlePlay}
        ></YoutubeBackgroundCustom>
        <div className="hero__content">
          <h2 className="hero__title">{title}</h2>
          <p className="hero__text">{subtitle}</p>
          <div onClick={() => this.smoothScroll('jobs')} className="hero__cta">
            {cta}
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
