import React from 'react';
import JobsList from '../JobsList/JobsList';

const department = props => {
  const { department, id, name } = props;

  if (department.hidden) {
    return false;
  } else {
    if (department.jobs && department.jobs.length > 0) {
      return (
        <div key={id} className="jobs__department">
          <h3 className="jobs__dep-name">{name}</h3>
          <JobsList department={department} />
        </div>
      );
    } else {
      return false;
    }
  }
};

export default department;
