import React from 'react';
import './Who.css';

const who = props => {
  const {
    content: { title, text, success }
  } = props;

  return (
    <section id="who" className="section section--who">
      <div className="section__wrapper section__wrapper--who">
        <div className="section__head section__head--who">
          <h2 className="section__title">{title}</h2>
        </div>
        <div className="section__content">
          <p className="section__text section__text--who">{text}</p>
          <div className="success">
            <div className="success__head">
              <h3 className="success__title blue-underline">{success.title}</h3>
            </div>
            <div className="success__items">
              {success.items.map((el, i) => {
                return (
                  <div key={i} className="success__item">
                    <p className="success__number">{el.number}</p>
                    <p className="success__text">{el.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default who;
