const data = {
  head: {
    title: 'Aktuelle Stellenangebote bei audibene',
    description:
      'Unsere Vision ist es, Menschen weltweit zu besserem Hören zu verhelfen. Werde Teil unseres bunten Teams und mache die Welt ein bisschen glücklicher.',
    favicon: 'favicon-de.ico'
  },
  benefits: {
    title: 'Benefits',
    items: [
      {
        title: 'Free fruits & drinks',
        icon: 'de/Benefits/fruits.svg',
        text:
          'Wir versorgen dich täglich mit Drinks, Müsli und Snacks. Deine Gesundheit unterstützen wir außerdem regelmäßig mit frischem Obst und knackigem Gemüse.'
      },
      {
        title: 'Fitness',
        icon: 'de/Benefits/fitness.svg',
        text:
          'Mit der audibene Gympass-Mitgliedschaft erhältst du ermäßigten Zugang zu mehr als 2.000 verschiedenen Fitness-, Sport- und Wellness-Angeboten in Deutschland.'
      },
      {
        title: 'Come as you are',
        icon: 'de/Benefits/shoe.svg',
        text:
          'Bei uns darf jeder so ins Office kommen, wie er mag. Casual in Shorts und Sneakers oder schick in Hemd und Chinos. Sei du selbst, dann fühlst du dich am wohlsten.'
      },
      {
        title: 'Startup feeling',
        icon: 'de/Benefits/rocket.svg',
        text:
          'Auch, wenn wir keins mehr sind. Einen Kicker, einen Grill, eine Dachterrasse und regelmäßige Team- und Company-Events haben wir trotzdem beibehalten.'
      }
    ],
    trustImg: {
      desktop: 'de/Trust/kununu_desktop.png',
      mobile: 'de/Trust/kununu_mobile.png'
    }
  },
  carousel: {
    slides: [
      {
        img: {
          mobile: 'de/Carousel/miracle_m.jpg',
          desktop: 'de/Carousel/miracle.jpg'
        },
        title: 'Vom Berliner Start-up zu einem der führenden Akustiker weltweit',
        text:
          'Wir sind der am schnellsten wachsende Hörgeräte-Akustiker, den es je gab. Nach kurzer Zeit haben wir uns von Berlin aus auf zwei weitere Kontinente ausgebreitet. Heute haben wir Büros in Berlin, Mainz, Miami, Denver, Toronto, Paris, Kuala Lumpur, Delhi, Utrecht, Bern und Seoul.'
      },
      {
        img: {
          mobile: 'de/Carousel/marco_and_paul_m.jpg',
          desktop: 'de/Carousel/marco_and_paul.jpg'
        },
        title: 'Eine Vision wird geboren',
        text:
          'Unsere Gründer Dr. Marco Vietor und Paul Crusius kennen sich schon aus der Uni. Gemeinsam hatten sie die Idee, mit Vorurteilen gegenüber Hörgeräten aufzuräumen und Menschen auf ganz moderne und einfache Weise zu besserem Hören – und damit zu mehr Lebensqualität – zu verhelfen.'
      },
      {
        img: {
          mobile: 'de/Carousel/wohnzimmer_m.jpg',
          desktop: 'de/Carousel/wohnzimmer.jpg'
        },
        title: 'Alles begann in diesem Wohnzimmer',
        text:
          '2012 starteten Marco, Paul und 3 weitere Kollegen mit dem audibene Geschäftsmodell. Die ersten telefonischen Kundengespräche fanden noch im Wohnzimmer von Marco statt. Ohne Schreibtische und mit nur einer Telefonleitung.'
      },
      {
        img: {
          mobile: 'de/Carousel/koepenicker_m.jpg',
          desktop: 'de/Carousel/koepenicker.jpg'
        },
        title: 'Das erste Büro',
        text:
          'Drei Monate später bezogen sie das erste offizielle audibene Büro in Berlin Kreuzberg: Schreibtische für alle und jede Menge Platz. Ab jetzt wurden täglich immer mehr Menschen hörglücklich gemacht und das Team wuchs so stark weiter, dass es auch hier bald deutlich zu eng wurde …'
      },
      {
        img: {
          mobile: 'de/Carousel/zaudibene_m.jpg',
          desktop: 'de/Carousel/zaudibene.jpg'
        },
        title: 'Aus klein wird groß',
        text:
          'Zwei weitere Umzüge und etlichen Zuwachs an Kollegen später findet man das audibene Headquarter heute mit rund 250 Mitarbeitern in Berlin Prenzlauer Berg sowie einen weiteren Firmensitz in Mainz. Eine gewisse Wohnzimmer-Dynamik soll beibehalten werden: Marco und Paul haben keine eigenen Büros, sondern sitzen zwischen uns allen.'
      },
      {
        img: {
          mobile: 'de/Carousel/us-office_m.jpg',
          desktop: 'de/Carousel/us-office.jpg'
        },
        title: 'Höher, schneller, weiter',
        text:
          'Die Vision unserer Gründer fand Anklang. Und das nicht nur in Deutschland – auch in weiteren Teilen Europas, in den USA und in Asien möchten Menschen ihr Leben mit audibene verbessern. Heute sorgen Marco und Paul mit rund 1.200 Kollegen aus 25 Nationen für gutes und einfaches Hören in 10 Ländern der Welt. Weitere Märkte nicht ausgeschlossen.'
      }
    ]
  },
  development: {
    title: 'Entwickle dich mit uns weiter',
    text:
      'Bei uns hast du etliche Möglichkeiten, dich nach deinen Wünschen weiterzuentwickeln. Wir glauben daran, dass Menschen nur dann im Job glücklich sind, wenn ihnen genügend Freiraum zur Selbstverwirklichung geboten wird. Damit wir nicht stehen bleiben – und du es auch nicht tust – setzen wir immer wieder auf persönliche Trainings und Perspektivenwechsel.',
    items: [
      {
        title: 'Im Team',
        img: 'de/Development/portrait_01.jpg',
        innerTitle: 'Christina',
        innerSubtitle: 'Head of Customer Sales Mainz',
        innerCopy:
          '„Ich kam 2015 als Kundenberaterin zu audibene nach Berlin. Inzwischen bin ich wieder in meine geliebte Heimat zurückgezogen und leite hier eins unserer Kundenberater-Teams am schönen Rhein in Mainz.“'
      },
      {
        title: 'Teamübergreifend',
        img: 'de/Development/portrait_02.jpg',
        innerTitle: 'Moritz',
        innerSubtitle: 'Business Analyst',
        innerCopy:
          '„Als Praktikant durfte ich in ganz verschiedene Bereiche schnuppern. Daten haben mich dabei immer interessiert. Heute bin ich audibene Business Analyst – wer eine Frage zu Daten hat, kommt zu mir.“'
      },
      {
        title: 'Persönlich',
        img: 'de/Development/portrait_03.jpg',
        innerTitle: 'Frania',
        innerSubtitle: 'Sales Operations Manager',
        innerCopy:
          '„Reisen ist meine Leidenschaft – privat und beruflich. Als Coach für unsere Hörgeräte-Experten war ich schon mehrere Monate in Toronto und in Miami. Weitere Trips für audibene sind nicht ausgeschlossen.“'
      }
    ]
  },
  footer: {
    social: [
      {
        title: 'Facebook',
        icon: 'facebook.svg',
        url: 'https://www.facebook.com/audibene/'
      },
      {
        title: 'Instagram',
        icon: 'instagram.svg',
        url: 'https://www.instagram.com/audibene_de/'
      },
      {
        title: 'youtube',
        icon: 'youtube.svg',
        url: 'https://www.youtube.com/channel/UCgxXCeJf-rlwNQK73J2nyPw'
      }
    ],
    international: {
      title: 'audibene/hear.com international:',
      links: [
        {
          title: 'Netherlands',
          icon: 'flag_netherlands@2x.png',
          url: 'https://www.audibene.nl'
        },
        {
          title: 'Switzerland',
          icon: 'flag_switzerland@2x.png',
          url: 'https://www.audibene.ch'
        },
        {
          title: 'Canada',
          icon: 'flag_canada@2x.png',
          url: 'https://www.hear.com/ca'
        },
        {
          title: 'France',
          icon: 'flag_france@2x.png',
          url: 'https://www.audibene.fr/'
        },
        {
          title: 'USA',
          icon: 'flag_usa@2x.png',
          url: 'https://www.hear.com/'
        },
        {
          title: 'India',
          icon: 'IN_flag@2x.png',
          url: 'https://www.hear.com/in'
        },
        {
          title: 'Malaysia',
          icon: 'flag_malaysia@2x.png',
          url: 'https://www.hear.com/my'
        },
        {
          title: 'Germany',
          icon: 'flag_germany@2x.png',
          url: 'https://www.audibene.de/'
        },
        {
          title: 'South Korea',
          icon: 'flag_south_korea@2x.png',
          url: 'https://www.hear.com/kr'
        }
      ]
    },
    nav: [
      {
        title: 'Impressum',
        url: 'https://www.audibene.de/impressum/'
      },
      {
        title: 'AGB',
        url: 'https://www.audibene.de/agb-datenschutz/'
      },
      {
        title: 'Datenschutz',
        url: 'https://www.audibene.de/datenschutz/'
      },
      {
        title: 'Presse',
        url: 'https://www.audibene.de/presse/'
      }
    ]
  },
  header: {
    logo: 'de/Logos/audibene-de.svg',
    items: [
      {
        text: 'Wer wir sind',
        anchor: 'who'
      },
      {
        text: 'Wie wir wachsen',
        anchor: 'development'
      },
      {
        text: 'Benefits',
        anchor: 'benefits'
      },
      {
        text: 'Jobs',
        anchor: 'jobs'
      }
    ]
  },
  hero: {
    img: 'de/Hero/hero-audibene-de.jpg',
    title: 'Jobs bei audibene',
    subtitle: 'Wir lassen Menschen besser hören.',
    cta: 'Finde deinen Job',
    videoId: '389282513'
  },
  jobs: {
    title: 'Jobs'
  },
  offices: {
    title: {
      mobile: 'Standorte in Deutschland',
      desktop: 'Standorte in Deutschland – Wo möchtest du arbeiten?'
    },
    items: [
      [
        {
          block: 'image',
          img: 'de/Offices/o_01.jpg'
        },
        {
          block: 'text',
          title: 'Berlin',
          text:
            'In unserem Headquarter in Berlin erwarten dich ca. 250 Kollegen, 5 Hunde und 200m² Dachterrasse. Auf 4 Etagen mit Loft-Charakter sorgen wir vom Prenzl Berg aus weltweit für gutes Hören. Hier ist der Ort, an dem die Marketing-Fäden all unserer Büros zusammenlaufen.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_02.jpg'
        }
      ],
      [
        {
          block: 'image',
          img: 'de/Offices/o_03.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_04.jpg'
        },
        {
          block: 'text',
          title: 'Mainz',
          text:
            'Im RheinCampus in Mainz vereinen wir unser internationales Schulungszentrum für unsere Hörberater, ein Hörakustik-Studio mit modernster Technik und Büroräume unserer Kundenberater-Teams – alles mit direktem Blick auf den wunderschönen Rhein.'
        }
      ],
      [
        {
          block: 'text',
          title: 'Dein Home Office',
          titleMobile: 'Home Office',
          text:
            'Zu Hause ists doch am schönsten? Bei uns hast du die Möglichkeit, deutschlandweit aus deinem Home Office zu arbeiten. Dank modernster Video-Kommunikationstechnik bist du zwar bequem bei dir zu Hause, fühlst dich deinen Kollegen aber trotzdem ganz nah.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_05.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_06.jpg'
        }
      ]
    ],
    single: {
      title: 'Wähle dein Traum-Büro',
      text:
        'Wir bieten dir die Möglichkeit, deutschlandweit mit uns zu arbeiten. Du entscheidest dich zwischen: Großstadtluft in unserem loftartigen Headquarter in Berlin, Blick auf den Rhein in unserem Office im idyllischen Mainz oder deinen ganz eigenen vier Wänden bei dir zu Hause.'
    }
  },
  values: {
    title: 'Unsere Werte',
    items: [
      {
        title: 'Transparenz',
        icon: 'de/Values/cards.svg',
        text:
          'Wir glauben an das Spielen mit offenen Karten. Deswegen erhältst du bei uns regelmäßig Einblick in aktuelle Strategien des Unternehmens.'
      },
      {
        title: 'Zahlen & Fakten',
        icon: 'de/Values/graph.svg',
        text:
          'Wir setzen auf Erfahrung und Bauchgefühl, lassen unsere Entscheidungen jedoch immer durch fundierte Tests und Analysen bestätigen.'
      },
      {
        title: 'Zwo, eins, Risiko',
        icon: 'de/Values/risk.svg',
        text:
          'Wir probieren lieber etwas aus, das scheitert, als gar nichts zu tun. Dabei handelt jeder von uns so, als sei es sein eigenes Unternehmen.'
      },
      {
        title: 'Work hard, play hard',
        titleMobile: 'Work & play hard',
        icon: 'de/Values/work-party.svg',
        text:
          'Um unsere Ziele zu erreichen, arbeiten wir hart. Neben exzellenter Performance kommt aber auch das Feiern unserer Erfolge nicht zu kurz.'
      }
    ]
  },
  who: {
    title: 'Wer wir sind',
    text:
      'Unsere Vision ist es, Menschen weltweit zu besserem Hören zu verhelfen. Dank modernster Technik in nahezu unsichtbarem Design muss heute niemand mehr mit schlechtem Hören leben. Werde Teil unseres bunten Teams und mache die Welt ein bisschen glücklicher.',
    success: {
      title: 'Unsere Erfolgsgeschichte',
      items: [
        {
          number: '2012',
          text: 'Gründung'
        },
        {
          number: '10',
          text: 'Länder'
        },
        {
          number: '1.200',
          text: 'Kollegen'
        },
        {
          number: '25',
          text: 'Nationen'
        }
      ]
    }
  },
  work: {
    title: 'Wie wir arbeiten',
    img: {
      desktop: 'de/Work/presentation.jpg',
      mobile: 'de/Work/presentation-mobile.jpg'
    },
    textBlock: {
      title: 'One team, one dream',
      text:
        'Wir arbeiten teamübergreifend an Projekten und denken nicht in Abteilungen. Hierarchien spielen bei uns kaum eine Rolle. Stattdessen kommunizieren wir auf Augenhöhe und schätzen jede Meinung – egal, ob vom Praktikanten oder vom Teamleiter.'
    }
  },
  video: {
    playImg: 'de/Video/play.svg',
    coverImg: 'de/Video/video-cover.jpg'
  }
};

export default data;
