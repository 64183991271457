import React, { Component } from 'react';
import './App.css';

import Header from '../Components/Header/Header';
import Hero from '../Components/Hero/Hero';
import Who from '../Components/Who/Who';
import Carousel from '../Components/Carousel/Carousel';
import Values from '../Components/Values/Values';
import Work from '../Components/Work/Work';
import Offices from '../Components/Offices/Offices';
import Benefits from '../Components/Benefits/Benefits';
import Video from '../Components/Video/Video';
import Development from '../Components/Development/Development';
import Jobs from '../Components/Jobs/Jobs';
import Footer from '../Components/Footer/Footer';
import {
  getLangFromLocale,
  getCountryFromLocale,
  getLocaleFromCountryAndLang
} from '../Helpers/Utils';
import { i18n, checkIfLangIsAvailable } from '../Translations/Translations';

class App extends Component {
  state = {
    locale: this.props.locale,
    hamburgerOpen: false,
    deviceWidth: window.innerWidth,
    content: i18n(this.props.locale)
  };

  handleHamClick() {
    this.setState({
      hamburgerOpen: !this.state.hamburgerOpen
    });
  }

  handleNavClick(target) {
    this.smoothScroll(target);
    if (this.state.deviceWidth < 992) {
      this.handleHamClick();
    }
  }

  handleLangClick(locale) {
    this.changeLocale(locale);
    window.history.pushState({}, '', `?lang=${getCountryFromLocale(locale).toLowerCase()}`);
  }

  changeLocale(locale) {
    this.setState({
      locale: locale,
      content: i18n(locale)
    });
  }

  handleLanguage() {
    const { locale } = this.state;
    const langSetViaUrlParam = new URLSearchParams(window.location.search).get('lang');
    let lang;

    if (langSetViaUrlParam && checkIfLangIsAvailable(langSetViaUrlParam, locale)) {
      lang = langSetViaUrlParam;
    } else {
      if (navigator.languages) {
        navigator.languages.forEach(
          navLang => (lang = checkIfLangIsAvailable(navLang, locale) && navLang)
        );
      }
    }

    if (lang && lang !== getLangFromLocale(locale)) {
      const newLocale = getLocaleFromCountryAndLang(lang, getCountryFromLocale(locale));
      this.changeLocale(newLocale);
    }
  }

  smoothScroll(target) {
    window.scrollTo({
      top: document.getElementById(target).offsetTop - (this.state.deviceWidth < 992 ? 90 : 120),
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    this.handleLanguage();
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ deviceWidth: window.innerWidth });
  };

  render() {
    const { deviceWidth, locale, content } = this.state;

    return (
      <div className="App">
        <Header
          isOpen={this.state.hamburgerOpen}
          navClick={this.handleNavClick.bind(this)}
          hamClick={this.handleHamClick.bind(this)}
          langClick={this.handleLangClick.bind(this)}
          deviceWidth={deviceWidth}
          locale={locale}
          content={content.header}
        />
        <Hero content={content.hero} />
        <Who content={content.who} />
        <Carousel deviceWidth={deviceWidth} content={content.carousel} />
        <Values deviceWidth={deviceWidth} content={content.values} />
        <Work deviceWidth={deviceWidth} content={content.work} />
        <Offices deviceWidth={deviceWidth} content={content.offices} />
        {locale !== 'en_US' && <Video deviceWidth={deviceWidth} content={content.video} />}
        <Development deviceWidth={deviceWidth} content={content.development} />
        <Benefits deviceWidth={deviceWidth} content={content.benefits} />
        <Jobs locale={locale} content={content.jobs} />
        <Footer content={content.footer} />
      </div>
    );
  }
}

export default App;
