import React from 'react';
import './Offices.css';

const offices = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, items, single },
    deviceWidth
  } = props;

  return (
    <section className="section section--offices">
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title hide-mobile blue-underline">{title.desktop}</h3>
          <h3 className="section__title hide-desktop blue-underline">{title.mobile}</h3>
        </div>

        <div className="section__content offices">
          {items.map((el, i) => {
            return (
              <div key={i} className="offices__row">
                {el.map((o, ii) => {
                  return (
                    <div key={ii} className={'offices__item offices__item--' + o.block}>
                      <div className={'offices__overlay--' + o.block}></div>
                      {o.block === 'image' ? (
                        <img alt="audibene office" className="offices__img" src={assets + o.img} />
                      ) : (
                        <div className="offices__text">
                          <p className="offices__name">
                            {o.titleMobile && deviceWidth < 992 ? o.titleMobile : o.title}
                          </p>
                          <p className="offices__body">{o.text}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {single && (
          <div className="section__content section__content--single hide-desktop">
            <h5 className="offices__subtitle">{single.title}</h5>
            <p className="offices__text offices__text--single">{single.text}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default offices;
