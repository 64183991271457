import React from 'react';
import Trust from '../Trust/Trust';
import './Benefits.css';

const benefits = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, items, trustImg },
    deviceWidth
  } = props;

  return (
    <section
      id="benefits"
      className={`section section--benefits ${deviceWidth >= 992 ? 'section--grey' : ''}`}
    >
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        <div className="section__content">
          <div className="benefits">
            {items.map((el, i) => {
              return (
                <div key={i} className="benefits__item">
                  <img alt="" className="benefits_icon" src={assets + el.icon} />
                  <h5 className="benefits__subtitle">{el.title}</h5>
                  <p className="benefits__text">{el.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        <Trust deviceWidth={deviceWidth} content={trustImg} />
      </div>
    </section>
  );
};

export default benefits;
