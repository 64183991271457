import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './YoutubeBackgroundCustom.module.css';
import Vimeo from '@u-wave/react-vimeo';

class YoutubeBackgroundCustom extends Component {
  constructor(props) {
    super(props);

    const aspectRatio = this.props.aspectRatio.split(':');

    this.state = {
      aspectRatio: aspectRatio[0] / aspectRatio[1],
      videoHeight: 10
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    const { aspectRatio } = this.state;
    const containerWidth = this.container.clientWidth;
    const containerHeight = this.container.clientHeight;
    const containerAspectRatio = containerWidth / containerHeight;

    let videoHeight = containerHeight;
    let videoWidth = containerWidth;
    let videoY = 0;
    let videoX = 0;

    if (containerAspectRatio > aspectRatio) {
      videoHeight = containerWidth / aspectRatio;
      videoY = (videoHeight - containerHeight) / -2;
    } else {
      videoWidth = containerHeight * aspectRatio;
      videoX = (videoWidth - containerWidth) / -2;
    }

    this.setState({
      videoHeight,
      videoWidth,
      videoY,
      videoX
    });
  }

  onPlay(event) {
    this.props.onPlay(event);
  }

  render() {
    const { videoHeight, videoWidth, videoX, videoY } = this.state;
    const { style, children, className, overlay, videoId } = this.props;

    return (
      <div
        style={style}
        ref={c => (this.container = c)}
        className={[styles.container, className].join(' ')}
      >
        <div>{children}</div>
        <div
          className={styles.videoContainer}
          style={{
            width: videoWidth + 'px',
            height: videoHeight + 'px',
            top: videoY + 'px',
            left: videoX + 'px'
          }}
        >
          {overlay && <div className={styles.overlay} style={{ backgroundColor: overlay }}></div>}
          <Vimeo
            video={videoId}
            autoplay
            className="hero__video-container"
            background={true}
            loop={true}
            onPlay={this.onPlay.bind(this)}
          />
        </div>
      </div>
    );
  }
}

YoutubeBackgroundCustom.propTypes = {
  videoId: PropTypes.string.isRequired,
  aspectRatio: PropTypes.string,
  overlay: PropTypes.string,
  className: PropTypes.string,
  onReady: PropTypes.func,
  onEnd: PropTypes.func
};

YoutubeBackgroundCustom.defaultProps = {
  aspectRatio: '16:9',
  overlay: 'false',
  nocookie: false,
  onReady: () => {},
  onPlay: () => {},
  onPause: () => {},
  onError: () => {},
  onEnd: () => {}
};

export default YoutubeBackgroundCustom;
