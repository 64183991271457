import React from 'react';
import './Footer.css';

const footer = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { social, international, nav }
  } = props;

  return (
    <footer className="section section--footer">
      <div className="section__wrapper">
        <div className="social">
          {social.map((el, i) => {
            return (
              <a
                key={i}
                className="social_link"
                href={el.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="" className="social__icon" src={assets + el.icon} />
              </a>
            );
          })}
        </div>

        <div className="international">
          <p className="international__title">{international.title}</p>
          <div className="international__flags">
            {international.links.map((el, i) => {
              return (
                <a
                  key={i}
                  className="international_link"
                  href={el.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="" className="international_icon" src={assets + el.icon} />
                </a>
              );
            })}
          </div>
        </div>

        <div className="nav">
          <div className="nav__links">
            {nav.map((el, i) => {
              return (
                <a
                  key={i}
                  className="nav_link"
                  href={el.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {el.title}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
