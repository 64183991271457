import React from 'react';
import './Values.css';

const values = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, items },
    deviceWidth
  } = props;

  return (
    <section className="section section--values">
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        <div className="section__content">
          <div className="values">
            {items.map((el, i) => {
              return (
                <div key={i} className="values__item">
                  <img alt="" className="values_icon" src={assets + el.icon} />
                  <h5 className="values__subtitle">
                    {el.titleMobile && deviceWidth < 400 ? el.titleMobile : el.title}
                  </h5>
                  <p className="values__text">{el.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default values;
