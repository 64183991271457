import React from 'react';
import './Work.css';

const work = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, img, textBlock },
    deviceWidth
  } = props;

  return (
    <section className="section section--work">
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        <div className="section__content work">
          <img
            className="work__img"
            alt=""
            src={assets + (deviceWidth >= 660 ? img.desktop : img.mobile)}
          />
          <div className="work__content">
            <h5 className="work__subtitle">{textBlock.title}</h5>
            <p className="work__text">{textBlock.text}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default work;
