const data = {
  head: {
    title: 'Aktuelle Stellenangebote bei audibene',
    description:
      'Unsere Vision ist es, Menschen weltweit zu besserem Hören zu verhelfen. Werde Teil unseres bunten Teams und mache die Welt ein bisschen glücklicher.',
    favicon: 'favicon-de.ico'
  },
  benefits: {
    title: 'Benefits',
    items: [
      {
        title: 'Free fruits & drinks',
        icon: 'de/Benefits/fruits.svg',
        text:
          'We provide for you free drinks, cereals and snacks, daily. We also regulary support your health with fresh fruits and tasty vegetables. Yummy!'
      },
      {
        title: 'Fitness',
        icon: 'de/Benefits/fitness.svg',
        text:
          'With our audibene Gympass membership, you get discounted access to more than 2,000 different fitness, sports and wellness providers in Germany.'
      },
      {
        title: 'Come as you are',
        icon: 'de/Benefits/shoe.svg',
        text:
          'At audibene, everyone comes to the office as they are. Casual in shorts and sneakers or fancy in shirt and chinos. Be yourself, feel comfortable.'
      },
      {
        title: 'Startup feeling',
        icon: 'de/Benefits/rocket.svg',
        text:
          'Even if we are one not anymore. A kicker table, a grill, a rooftop terrace and regular team and company events are still key pieces of our daily company life.'
      }
    ],
    trustImg: {
      desktop: 'de/Trust/kununu_desktop.png',
      mobile: 'de/Trust/kununu_mobile.png'
    }
  },
  carousel: {
    slides: [
      {
        img: {
          mobile: 'de/Carousel/miracle_m.jpg',
          desktop: 'de/Carousel/miracle.jpg'
        },
        title: 'From a Berlin start-up to one of the leading hearing care companies worldwide',
        text:
          'We are the fastest growing hearing care company ever. Shortly after starting in Berlin we expanded from there to two further continents. Today, we have offices in Berlin, Mainz, Miami, Denver, Toronto, Paris, Kuala Lumpur, Delhi, Utrecht, Berne and Seoul.'
      },
      {
        img: {
          mobile: 'de/Carousel/marco_and_paul_m.jpg',
          desktop: 'de/Carousel/marco_and_paul.jpg'
        },
        title: 'A vision is born',
        text:
          'Our founders Marco Vietor and Paul Crusius already knew each other from university. Together, they had the idea to fight prejudices about hearing aids. Ultimately, they wanted to help people, in an innovative and simple way, to better hearing - and thus, a better quality of life.'
      },
      {
        img: {
          mobile: 'de/Carousel/wohnzimmer_m.jpg',
          desktop: 'de/Carousel/wohnzimmer.jpg'
        },
        title: 'It all started in this living room',
        text:
          "In 2012 Marco, Paul and 3 other colleagues started audibene. Actually, our first phone calls were done out of Marco's living room. Without any desks, only with laptops and phones."
      },
      {
        img: {
          mobile: 'de/Carousel/koepenicker_m.jpg',
          desktop: 'de/Carousel/koepenicker.jpg'
        },
        title: 'Our first office',
        text:
          'Three months later they moved into the first real office in Berlin Kreuzberg: desks for everyone and plenty of space. From then on, they helped more and more people every day and our team grew so quickly that it soon became quite crowded again...'
      },
      {
        img: {
          mobile: 'de/Carousel/zaudibene_m.jpg',
          desktop: 'de/Carousel/zaudibene.jpg'
        },
        title: 'Growing up',
        text:
          'Two more relocations and a number of new colleagues later, we opend up the audibene headquarters with around 250 employees in Prenzlauer Berg Berlin and another office in Mainz. It was important to maintain a living room dynamic.'
      },
      {
        img: {
          mobile: 'de/Carousel/us-office_m.jpg',
          desktop: 'de/Carousel/us-office.jpg'
        },
        title: 'Higher, faster, further',
        text:
          'Our vision was well received. Not only in Germany, but in other parts of Europe, in the US and in Asia, people wanted to improve their lives with audibene. Today, all colleagues from over 25 nations ensure good and easy hearing in over 10 countries all around the world.'
      }
    ]
  },
  development: {
    title: 'Develop yourself',
    text:
      'We have a ton of possibilities to develop yourself on the topics most relevant to you. We believe that people are happy only when they have enough freedom and ownership. So we never stop, and neither should you. We always rely on personal development and changes in perspective.',
    items: [
      {
        title: 'In your team',
        img: 'de/Development/portrait_01.jpg',
        innerTitle: 'Christina',
        innerSubtitle: 'Head of Customer Sales Mainz',
        innerCopy:
          '„In 2015, I moved to Berlin and started at audibene as a sales rep. In the meantime, I have moved back to my beloved home town and lead one of our sales teams near the beautiful Rhine river in Mainz.“'
      },
      {
        title: 'Across teams',
        img: 'de/Development/portrait_02.jpg',
        innerTitle: 'Moritz',
        innerSubtitle: 'Business Analyst',
        innerCopy:
          "„As an intern, I was able to get a taste of different areas. Data has always interested me. Today I'm our leading Business Analyst - whoever has questions on data comes to me.“"
      },
      {
        title: 'Personally',
        img: 'de/Development/portrait_03.jpg',
        innerTitle: 'Frania',
        innerSubtitle: 'Sales Operations Manager',
        innerCopy:
          "„Travel is my passion - privately as well as professionally. As a coach for our hearing care professionals, I've been in Toronto and Miami for several months. More trips are always welcome.“"
      }
    ]
  },
  footer: {
    social: [
      {
        title: 'Facebook',
        icon: 'facebook.svg',
        url: 'https://www.facebook.com/audibene/'
      },
      {
        title: 'Instagram',
        icon: 'instagram.svg',
        url: 'https://www.instagram.com/audibene_de/'
      },
      {
        title: 'youtube',
        icon: 'youtube.svg',
        url: 'https://www.youtube.com/channel/UCgxXCeJf-rlwNQK73J2nyPw'
      }
    ],
    international: {
      title: 'audibene/hear.com international:',
      links: [
        {
          title: 'Netherlands',
          icon: 'flag_netherlands@2x.png',
          url: 'https://www.audibene.nl'
        },
        {
          title: 'Switzerland',
          icon: 'flag_switzerland@2x.png',
          url: 'https://www.audibene.ch'
        },
        {
          title: 'Canada',
          icon: 'flag_canada@2x.png',
          url: 'https://www.hear.com/ca'
        },
        {
          title: 'France',
          icon: 'flag_france@2x.png',
          url: 'https://www.audibene.fr/'
        },
        {
          title: 'USA',
          icon: 'flag_usa@2x.png',
          url: 'https://www.hear.com/'
        },
        {
          title: 'India',
          icon: 'IN_flag@2x.png',
          url: 'https://www.hear.com/in'
        },
        {
          title: 'Malaysia',
          icon: 'flag_malaysia@2x.png',
          url: 'https://www.hear.com/my'
        },
        {
          title: 'Germany',
          icon: 'flag_germany@2x.png',
          url: 'https://www.audibene.de/'
        },
        {
          title: 'South Korea',
          icon: 'flag_south_korea@2x.png',
          url: 'https://www.hear.com/kr'
        }
      ]
    },
    nav: [
      {
        title: 'Imprint',
        url: 'https://www.audibene.de/impressum/'
      },
      {
        title: 'Terms & Conditions',
        url: 'https://www.audibene.de/agb-datenschutz/'
      },
      {
        title: 'Privacy Policy',
        url: 'https://www.audibene.de/datenschutz/'
      },
      {
        title: 'Press',
        url: 'https://www.audibene.de/presse/'
      }
    ]
  },
  header: {
    logo: 'de/Logos/audibene-de.svg',
    items: [
      {
        text: 'Who we are',
        anchor: 'who'
      },
      {
        text: 'How we grow',
        anchor: 'development'
      },
      {
        text: 'Benefits',
        anchor: 'benefits'
      },
      {
        text: 'Jobs',
        anchor: 'jobs'
      }
    ]
  },
  hero: {
    img: 'de/Hero/hero-audibene-de.jpg',
    title: 'Jobs at audibene',
    subtitle: "We make people's lives better.",
    cta: 'Open positions',
    videoId: '389282513'
  },
  jobs: {
    title: 'Jobs'
  },
  offices: {
    title: {
      mobile: 'Locations in Germany',
      desktop: 'Locations in Germany - Where do you want to work?'
    },
    items: [
      [
        {
          block: 'image',
          img: 'de/Offices/o_01.jpg'
        },
        {
          block: 'text',
          title: 'Berlin',
          text:
            'In our headquarter in Berlin, you can find about 250 colleagues, 5 dogs and 200m² roof-top terrace. On 4 floors with loft character, we provide great hearing all around the world. This is the place where most of our global teams as well as our European sales teams are located.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_02.jpg'
        }
      ],
      [
        {
          block: 'image',
          img: 'de/Offices/o_03.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_04.jpg'
        },
        {
          block: 'text',
          title: 'Mainz',
          text:
            'At the RheinCampus in Mainz, where we  combine our training center for audiologists, a hearing studio with state-of-the-art technology, with four sales teams - all with a beautiful view of the Rhine river.'
        }
      ],
      [
        {
          block: 'text',
          title: 'Home Office',
          titleMobile: 'Home Office',
          text:
            'Your home is a more beautiful office? At audibene, you always have the opportunity to work a couple days (or always) from home. Thanks to state-of-the-art video communication technology, you can work comfortably from home, but still feel closely connected to your colleagues.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_05.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_06.jpg'
        }
      ]
    ],
    single: {
      title: 'Wähle dein Traum-Büro',
      text:
        'Wir bieten dir die Möglichkeit, deutschlandweit mit uns zu arbeiten. Du entscheidest dich zwischen: Großstadtluft in unserem loftartigen Headquarter in Berlin, Blick auf den Rhein in unserem Office im idyllischen Mainz oder deinen ganz eigenen vier Wänden bei dir zu Hause.'
    }
  },
  values: {
    title: 'Our values',
    items: [
      {
        title: 'Transparency',
        icon: 'de/Values/cards.svg',
        text:
          "We believe in complete transparency. That's why everyone gets regular insight into our current strategy and challenges."
      },
      {
        title: 'Data-driven decisions',
        icon: 'de/Values/graph.svg',
        text:
          'We trust our experience and gut feeling. Nevertheless, we always confirm/disprove our decisions by coduncting A/B tests and analyzes.'
      },
      {
        title: 'Risk-seeking',
        icon: 'de/Values/risk.svg',
        text:
          "We'd rather try something that fails than do nothing. Each of us acts as if we were the founders of audibene."
      },
      {
        title: 'Work hard, play hard',
        titleMobile: 'Work & play hard',
        icon: 'de/Values/work-party.svg',
        text:
          'We work hard for our goals. In addition to excellent results, we love celebrating success with colleagues all around the world.'
      }
    ]
  },
  who: {
    title: 'Who we are',
    text:
      'Our vision is to help people around the world to hear better. Thanks to state-of-the-art technology with an almost invisible design, today, nobody has to live with bad hearing. Become part of our colourful team and make the world a better place.',
    success: {
      title: 'Our success story',
      items: [
        {
          number: '2012',
          text: 'Founded'
        },
        {
          number: '10',
          text: 'Countries'
        },
        {
          number: '1.200',
          text: 'Colleagues'
        },
        {
          number: '25',
          text: 'Nationalities'
        }
      ]
    }
  },
  work: {
    title: 'How we work',
    img: {
      desktop: 'de/Work/presentation.jpg',
      mobile: 'de/Work/presentation-mobile.jpg'
    },
    textBlock: {
      title: 'One team, one dream',
      text:
        "We work on projects across teams and don't think or act in departments. Hierarchies hardly play a role. Instead, we communicate on equal terms and value each other's opinions - no matter what your experience level is."
    }
  },
  video: {
    playImg: 'de/Video/play.svg',
    coverImg: 'de/Video/video-cover.jpg'
  }
};

export default data;
